/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTasting = /* GraphQL */ `
  query GetTasting($id: ID!) {
    getTasting(id: $id) {
      id
      distillery
      expression
      age
      proof
      region
      comments
      createdAt
      updatedAt
    }
  }
`;
export const listTastings = /* GraphQL */ `
  query ListTastings(
    $filter: ModelTastingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTastings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        distillery
        expression
        age
        proof
        region
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
