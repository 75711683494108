import * as React from "react";
interface State {
  distillery: string;
  expression: string;
  age: string;
  proof: string;
}
interface Props {
  onSubmit: (formValues: State) => void;
}
export default class Form extends React.PureComponent<Props, State> {
  state = {
    proof: "",
    distillery: "",
    age: "",
    expression: ""
  };
handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as any);
  };
render() {
    return (
      <form
        onSubmit={async e => {
          e.preventDefault();
          this.props.onSubmit(this.state);
        }}
      >
        <h3>Create Blog</h3>
        <input
          name="distillery"
          placeholder="name"
          value={this.state.distillery}
          onChange={this.handleChange}
        />
        <input
          name="expression"
          placeholder="name"
          value={this.state.expression}
          onChange={this.handleChange}
        />
        <input
          name="age"
          placeholder="name"
          value={this.state.age}
          onChange={this.handleChange}
        />
        <input
          name="proof"
          placeholder="name"
          value={this.state.proof}
          onChange={this.handleChange}
        />                                
        <button type="submit">save</button>
      </form>
    );
  }
}