import * as React from "react";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { listTastings } from "../graphql/queries";
import Tasting from "./Models"
export default class Tastings extends React.PureComponent {
  render() {
    return (
      <Connect query={graphqlOperation(listTastings)}>
        {({ data: { listTastings: tastings } }: any) => {
          if (!tastings) {
            return <div>Error loading data</div>;
          }
return (
            <div>
              {tastings.items.map((t: any) => (
                <Tasting data={t}/>
              ))}
            </div>
          );
        }}
      </Connect>
    );
  }
}