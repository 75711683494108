import React, { Component } from 'react';


class Tasting extends Component {
    render () {
        return (
        <div className="four columns review-col" key={this.props.data.distillery}><h3>{this.props.data.distillery}</h3>
        <p className="info">{this.props.data.expression}</p>
        <p className="details"><span>&bull;</span> <em>Age:</em> <em className="age">{this.props.data.age}</em></p>
        <p className="details"><span>&bull;</span> <em>Proof:</em> <em className="proof">{this.props.data.proof}</em></p>
        <p className="details"><span>&bull;</span> <em>Comments:</em> <em className="blurb">{this.props.data.comments}</em></p>
        <p className="description">{this.props.data.description}</p>
    </div>
        )}
}

export default Tasting