import React, { Component } from 'react';

import Tasting from './Models'
import Tastings from './Tastings'

class Reviews extends Component {
  render() {
    return (
      <section id="reviews">
      <div className="row">
            <div className="nine columns header-col">
            <h1><span>Reviews</span></h1>
          </div>
      </div>
      <div className="row review">
          <div className="twelve columns main-col">
          <Tastings />
        </div>
    </div>
   </section>
    );
  }
}

export default Reviews;
