/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_content_delivery_bucket": "themudhookwhiskyclub.com-dev",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "https://dsg01vlkinpy8.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://ad2d4utzvbedtlmgv6ahjezz2i.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nufm6qtm7vcrjiy34d3pfaeljq",
    "aws_cognito_identity_pool_id": "eu-west-2:22d7901e-f425-4c7a-8dd6-6f6fffe63c72",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_bkqvLpa48",
    "aws_user_pools_web_client_id": "14n79uqsuee5603jk4kvjk9m68",
    "oauth": {}
};


export default awsmobile;
