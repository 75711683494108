import React, { Component } from 'react';
import $ from 'jquery';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Reviews from "./Components/Reviews";
import Form from './Components/Form';
import Contact from './Components/Contact';
import Amplify, {Auth, API, graphqlOperation} from 'aws-amplify';
import { Connect } from "aws-amplify-react";
import { createTasting } from "./graphql/mutations";

import awsconfig from './aws-exports';


Amplify.configure(awsconfig);

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      siteData: {}
    };

  }

  getSiteData(){
    $.ajax({
      url:'siteData.json',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({siteData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getSiteData();
  }

  render() {
    return (
      <div className="App">
        <Header data={this.state.siteData.main}/>
        <Reviews />
        {/* <Testimonials data={this.state.resumeData.testimonials}/> */}
        <About data={this.state.siteData.main}/>

        <Contact data={this.state.siteData.main}/>
        <Footer data={this.state.siteData.main}/>
        {/* <Connect mutation={graphqlOperation(createTasting)}>
          {({ mutation }) => (
            <Form
              onSubmit={async input => {
                const response = await mutation({
                  input
                });
                console.log(response);
              }}
            />
          )}
        </Connect> */}
      </div>
    );
  }
}

export default App;
